<template>
    <div class="pageMain">
      <div class="flex">
        <!-- 组织项目树 -->
  
        <OriginTreeCompany class="tree" :filterText="filterText" @checked="init">
          <template slot="search">
            <SearchLeft
              @search="search"
              @reset="reset"
              info="项目信息"
              :iconShow="false"
            >
              <el-form :inline="true" :model="searchForm">
                <el-form-item label="" class="formItem">
                  <el-input
                    v-model="filterText"
                    size="small"
                    placeholder="项目"
                  ></el-input>
                </el-form-item>
              </el-form>
            </SearchLeft>
          </template>
        </OriginTreeCompany>
  
        <!--右边列表  -->
        <div class="form">
          <SearchHead @search="search" @reset="reset">
            <el-form :inline="true" :model="searchForm">
              <el-form-item label="现场编号">
                <el-input
                  v-model="searchForm.deviceNo"
                  size="small"
                  placeholder="现场编号"
                ></el-input>
              </el-form-item>
  
              <el-form-item label="主机IMEI">
                <el-input
                  v-model="searchForm.deviceImei"
                  size="small"
                  placeholder="主机IMEI"
                ></el-input>
              </el-form-item>
            </el-form>
          </SearchHead>
          <deviceNum :count="count"></deviceNum>
          <CustomTables
            :data="tableData"
            :headerCellStyle="headerCellStyle"
            @selectionChange="handleSelectionChange"
            @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
          
            :total="total"
            title="实时监控"
          >
            <!-- <template slot="btns">
              <el-button
                type="success"
                size="small"
                @click="addFrom"
                icon="el-icon-plus"
                class="btn"
                >新建</el-button
              >
            </template> -->
  
            <el-table-column type="selection" align="center" width="55">
            </el-table-column>
            <el-table-column prop="compName" label="公司名称" align="center">
            </el-table-column>
            <el-table-column prop="deviceCount" label="设备数" align="center">
            </el-table-column>
            <el-table-column prop="deviceOnlineCount" label="在线设备数" align="center">
            </el-table-column>
            <el-table-column prop="illegalCount" label="违章数" align="center">
            </el-table-column>
            <el-table-column prop="alarmCount" label="报警数" align="center">
            </el-table-column>
           
            <!-- <el-table-column label="操作" align="center" width="100">
              <template slot-scope="scope">
                <span
                  @click="detailItem(scope.row)"
                  class="detail"
                >
                <span>查看</span>
              </span>
           
              </template>
            </el-table-column> -->
          </CustomTables>
        </div>
      </div>
      <!-- <personnelBindingEdit
        :visible.sync="showDialog"
        @close="colseDialog"
        :title="title"
      ></personnelBindingEdit> -->
    </div>
  </template>
  
  <script>
  import OriginTreeCompany from "../../../components/common/OriginTreeCompany.vue";
  import SearchHead from "../../../components/common/SearchHead";
  import CustomTables from "../../../components/common/CustomTables";
  import SearchLeft from "../../../components/common/SearchLeft.vue";
  import personnelBindingEdit from "../../../components/basic/personnelBindingEdit.vue";
  import deviceNum from '../../../components/monitor/deviceNum.vue'
  import monitor from "../../../api/modules/monitor";
  export default {
    components: {
      OriginTreeCompany,
      SearchHead,
      CustomTables,
      SearchLeft,
      personnelBindingEdit,
      deviceNum
    },
    name: "statistics",
    data() {
      return {
        searchForm: {
          currPage: 1,
          pageSize: 10,
        },
        tableData: [],
        options: [],
        headerCellStyle: {
          backgroundColor: "#f3f2f7",
          color: "#373628",
        },
        total: 0,
        show: true,
        showDialog: false,
        title: "",
        filterText: "",
        count:''
      };
    },
    mounted() {},
    methods: {
      addFrom() {
        this.showDialog = true;
        this.title = "绑定操作员";
      },
      init(val) {
        console.log('val',val);
        this.searchForm.currPage = 1;
        this.searchForm.orgaId = val.orgaId;
        this.orgaId=val.orgaId;
        this.getList();
      },
      getList() {
        monitor.getRealListByPage(this.searchForm).then((res) => {
          this.total = res.data.total;
          this.tableData = res.data.records;
        });
        monitor.getRealCount(this.searchForm).then((res) => {

            this.count=res.data
        //   this.total = res.data.total;
        //   this.tableData = res.data.records;
        });
      },
      search() {
        console.log("searchForm", this.searchForm);
        this.searchForm.currPage = 1;
        this.searchForm.pageSize = 10;
        this.getList();
      },
      reset() {
        this.searchForm = {
          currPage: 1,
          pageSize: 10,
          orgaId:this.orgaId
        };
        this.getList();
      },
      tableExport() {
        // 导出
      },
      handleSelectionChange(val) {
        console.log(val);
      },
      detailItem() {
        //详情
      },
      deleteItem() {},
      colseDialog() {
        this.showDialog = false;
      },
      handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex
      this.getList()
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.currPage = 1
      this.getList()
    },
    },
  };
  </script>
  
  <style scoped lang="less">
.flex {
  display: flex;
  height: 100%;
  
  // justify-content: space-between;
}
.tree {
  width: 20%;
  height: 96%;
  
}
  .form {
    width: 78%;
    margin-left: 2%;
  }
  .formItem {
    margin-bottom: 0px;
  }
  .btn {
    background-color: #008f4d;
    // color: #008f4d;
  }
  
  .detail {
    color: #008f4d;
  }
  </style>
  