<!--  -->
<template>
 <div class="equmentBox">
    <div class="childBox">
        <div>全部设备</div>
        <div>{{ count.deviceCount }}</div>
    </div>
    <div class="childBox">
        <div>在线设备</div>
        <div>{{ count.deviceOnlineCount }}</div>
    </div>
    <div class="childBox">
        <div>异常设备</div>
        <div>{{ 0 }}</div>
    </div>
    <div class="childBox">
        <div>离线设备</div>
        <div>{{ count.deviceOffCount }}</div>
    </div>
 </div>
</template>

<script>
export default {
    props:['count'],
 components:{

 },
 data () {
  return {

  }
 },
 mounted(){

 },
 methods:{

 },
}

</script>

<style lang='less' scoped>
.equmentBox{
    display: flex;
 
    .childBox{
        margin-right: 10px;
        padding: 10px;
        width: 150px;
        border-radius: 6%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #008f4d;
        color: #fff;
        background-color: #008f4d;
        &:nth-child(2){
            background-color: #fff;
            border: 1px solid #008f4d;
            color: #008f4d;
        }
        &:nth-child(3){

            background-color: #fff;
            border: 1px solid #ff3f3f;
            color: #ff3f3f;
        }
        &:nth-child(4){

            background-color: #fff;
            border: 1px solid #f2f2f2;
            color:#999999;
        }
    }
}
</style>
